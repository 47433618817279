import React, { Fragment, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Logo from "@/images/new/triplice/logo.svg";
import Img1 from '@/images/new/triplice/bgCity.png'
import City from '@/images/new/triplice/city.png'
import Lupa from "@/images/new/triplice/lupa.png";
import Letras from "@/images/new/triplice/letras.svg";
import Itaipu from "@/images/new/triplice/itaipu.svg";
import Sapientia from "@/images/new/landingPageEs/sapientia.svg";
import Vertere from "@/images/new/landingPageEs/vertere.svg";
import Fractal from "@/images/new/landingPageEs/fractal.svg";
import Regulamento from '../assets/documents/Olimpiada-Triple-Frontera.pdf'

// function onChange(value) {
//   console.log("Captcha value:", value);
// }

const Finalistas = ({}) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <Fragment>
      <section
        className="pageLanding"
        style={{ background: "#e5e5e5", paddingBottom: 150, paddingTop: 80 }}
      >
        <div className="container" style={{ position: "relative" }}>
          <div className="lang-menu">
            <ul>
              <li>Idiomas:</li>
              <li>
                <a href="#" className="selected">PT</a>
              </li>
              <li>
              <a href="/triple-frontera">ES</a>
              </li>
            </ul>
          </div>

          <div className="row" data-aos="fade-right" data-aos-once="true">
            <img src={Logo} className="MR-M-16" />
          </div>
          <div className="img-header" data-aos="fade-in" data-aos-once="true">
            <div className="city">
              <img className="img-size" src={City} />
            </div>
            <img className="img-size" src={Img1} />
            <div className="shadow-city"></div>
          </div>
          <div className="row">
            <div className="img-header d-none-img-mob">
              <div className="city">
                <img className="img-size" src={City} />
              </div>
              <img className="img-size" src={Img1} />
              <div className="shadow-city"></div>
            </div>
            <div
              className="col-lg-5 col-sm-12"
              data-aos="fade-right"
              data-aos-once="true"
            >
              <div className="row">
                <div className="header_content PT-32">
                  <p>
                  Uma olimpíada para alunos e professores da região das fronteiras entre Argentina, Brasil e Paraguai que querem colocar suas ideias sobre desenvolvimento sustentável em prática!
                  </p>
                </div>
                <div className="cronograma">
                  <h5>Cronograma</h5>
                  <p>
                    Inscrições: <strong>até 26 de fevereiro</strong>
                    {/* <br />
                    Entrega dos projetos: <strong>25 e 26 de novembro</strong>
                    <br />
                    Divulgação dos finalistas: <strong>28 de novembro</strong>
                    <br />
                    Apresentação final: <strong>2 de dezembro</strong> */}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7 d-none-img-desk" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Img1} style={{ width: '100%' }} />
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="section-blue"
        style={{ background: "#5287DD", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Iniciativa do Instituto Vertere</h2>
              <p style={{ marginBottom: 0 }}>
              Somos uma olimpíada afiliada da Sapientia , a Olimpíada do Futuro. A Sapientia é uma iniciativa realizada pelo Instituto Vertere e tem como base a Agenda de 2030 da organização das Nações Unidas -  ONU. A competição aborda as disciplinas estudadas em sala de aula a partir de uma perspectiva conectada às questões do nosso tempo e enriquecida pela visão de pensadores do século 21.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section-gray"
        style={{ background: "#e5e5e5", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Intercâmbio de aprendizados</h2>
              <p>
              Chegamos à Tríplice Fronteira para celebrar a expansão das olimpíadas do Instituto Vertere para os países de língua espanhola da América do Sul!
              </p>
              <a className="cta-inscricao" href="https://app.olimpiadadofuturo.com.br/">
                Inscrições abertas até 26 de fevereiro
              </a>
              <a className="cta-regulamento" href={Regulamento} target='_blank'>
                Acesse o regulamento
              </a>
            </div>
            <div className="col-lg-6">
              <img src={Lupa} className="lupa" />
            </div>
          </div>
        </div>
      </section>
      <section
        className="section-blue text-center"
        style={{ background: "#5287DD", padding: "72px 0" }}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 style={{ marginBottom: 32 }}>
                Inscreva-se
                <br />
                gratuitamente
              </h2>
              <a className="cta-inscricao" href="https://app.olimpiadadofuturo.com.br/">
                Quero me inscrever
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ background: "#e5e5e5", padding: "60px 0" }}
        data-aos="zoom-in-down"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 footer">
              <ul className="footer-logos">
                <li>Realização:</li>
                <li>
                  <a href="https://olimpiadadofuturo.com.br/" target="_blank">
                    <img src={Sapientia} />
                  </a>
                </li>
                <li>
                  <a href="https://institutovertere.org/" target="_blank">
                    <img src={Vertere} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12 footer">
              <ul className="footer-logos">
                <li>Apoio:</li>
                <li>
                  <a href="https://www.itaipu.gov.br/" target="_blank">
                    <img src={Itaipu} />
                  </a>
                </li>
                <li>
                  <a href="https://fractaltecnologia.com.br/" target="_blank">
                    <img src={Fractal} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Finalistas;
